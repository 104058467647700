.w-100 {
  width: 100% !important;
}

.w-50 {
  width: 50% !important;
}

.h-100 {
  height: 100% !important;
}

.d-flex {
  display: flex !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-1 {
  flex: 1 !important;
}

.align-items-center {
  align-items: center !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.d-none {
  display: none !important;
}

.d-block {
  display: block !important;
}

.user-select-none {
  user-select: none !important;
}

.visibility-hidden {
  visibility: hidden !important;
}

// Spacing
.ml-auto {
  margin-left: auto;
}

.text-center{
  text-align: center !important;
}
