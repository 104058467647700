:root {
    --white-color: #ffffff;
    --black-color: #000000;
    --primary-color: #64B5F6;
    --color-red: #FF0000FF;
    --secondary-color: #3b3b3b;
    --collapsed-sider-icon-color: #a1a1a1;
    --header-color: #c4c6c8;
    --tab-background-color: #D5D2D8;
    --dark-grey-color: #686669;
    --light-grey-color: #7F7D81;
    --card-border-color: #D2D4D5;
    --button-border-color: #D9D9D9;
    --active-tab-color: #4F4E50;
    --dark-row-background: #C6C8CE;
    --ongoing-call-background: #E6E7E9;
    --board-item-border: #707070;
    --arrow-color: #ACACAC;
    --color-white: #FFFFFF;
    --phone-numbers-color: #3F3F3F;
    --hovered-buttons-color: #eee;
    --silver: #E8E8E8;
    --metal-grey: #DBDCDD;
    --call-button-color: #64D664;
    --hang-up-color: #F85E5E;
    --modal-border-color: #f0f0f0;
    --info-alert-background: #e3f2fd;
    --info-alert-border: #91d5ff;
    --small-font-size: 12px;
    --middle-font-size: 16px;
    --large-font-size: 20px;
}
