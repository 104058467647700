/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap");
@import "~jsoneditor/dist/jsoneditor.min.css";

body {
  font-family: "Montserrat", sans-serif !important;
}

.ant-layout {
  background-color: var(--modal-border-color);
}

.ant-pagination-item {
  color: var(--secondary-color)
}
.ant-pagination-item-active a {
  background-color: var(--primary-color);
  color: var(--secondary-color);
  &:hover {
    background-color: var(--primary-color);
    color: var(--secondary-color);
  }

}
.ant-table-thead > .ant-table-row > .ant-table-cell {
  background-color: var(--primary-color);
  color: var(--white-color);
}
